exports.onRouteUpdate = () => {
  function sendAnalyticsEvent() {
    if (window?.analytics) {
      window.analytics.page();
    }
  }

  const myTimeout = setTimeout(sendAnalyticsEvent, 1000);
  return () => {
    clearTimeout(myTimeout);
  };
};
